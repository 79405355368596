exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-quiz-result-a-archetype-slug-thank-you-tsx": () => import("./../../../src/pages/quiz/result_A/{Archetype.slug}/thank-you.tsx" /* webpackChunkName: "component---src-pages-quiz-result-a-archetype-slug-thank-you-tsx" */),
  "component---src-pages-quiz-result-a-archetype-slug-tsx": () => import("./../../../src/pages/quiz/result_A/{Archetype.slug}.tsx" /* webpackChunkName: "component---src-pages-quiz-result-a-archetype-slug-tsx" */),
  "component---src-pages-quiz-result-archetype-slug-thank-you-tsx": () => import("./../../../src/pages/quiz/result/{Archetype.slug}/thank-you.tsx" /* webpackChunkName: "component---src-pages-quiz-result-archetype-slug-thank-you-tsx" */),
  "component---src-pages-quiz-result-archetype-slug-tsx": () => import("./../../../src/pages/quiz/result/{Archetype.slug}.tsx" /* webpackChunkName: "component---src-pages-quiz-result-archetype-slug-tsx" */),
  "component---src-pages-quiz-result-b-archetype-slug-thank-you-tsx": () => import("./../../../src/pages/quiz/result_B/{Archetype.slug}/thank-you.tsx" /* webpackChunkName: "component---src-pages-quiz-result-b-archetype-slug-thank-you-tsx" */),
  "component---src-pages-quiz-result-b-archetype-slug-tsx": () => import("./../../../src/pages/quiz/result_B/{Archetype.slug}.tsx" /* webpackChunkName: "component---src-pages-quiz-result-b-archetype-slug-tsx" */),
  "component---src-pages-quiz-tsx": () => import("./../../../src/pages/quiz.tsx" /* webpackChunkName: "component---src-pages-quiz-tsx" */)
}

